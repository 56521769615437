@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Tamil:wght@500;700&family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply font-body bg-primary-05;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply capitalize;
    }
}

header {
    @apply sticky top-0 z-50 backdrop-blur-xl bg-white bg-primary-05 opacity-90;

    nav a {
        @apply px-2 lg:ml-12 ml-4 py-2 transition-all duration-500 ease-in-out text-lg border-b-4 border-transparent;
        &:hover {
            @apply border-primary-500 font-normal;
        }
        &.active {
            @apply border-primary-500 font-bold border-white;
        }
        &.dwl {
            @apply bg-primary-500 text-white rounded-md px-6;
        }        
    }
    .padd {
        @apply py-2;
    }
    .fgrow {
        @apply flex-grow;
    }
}

// Work list
.intro {
    height: 50vh;
    @apply pt-5 lg:pt-10;
    h1 {
        @apply text-5xl tracking-tighter lg:text-8xl font-bold mt-4 normal-case;
    }
    p {
        @apply text-2xl font-thin leading-relaxed my-8 lg:text-4xl;
    }
}

a.cta {
    // @apply w-36 p-2 border-2 text-center border-primary-500 text-primary-500 rounded-md mt-4;
    // @apply w-36 p-2 text-center bg-primary-500 text-white rounded-md mt-4;
    //@apply block p-4 mt-4 text-lg text-primary-500 text-center;
    width: 160px;
    @apply mx-auto block px-1 py-2 border-4 font-medium text-center border-primary-500 text-primary-500 rounded-full mt-6 transition-all duration-150 ease-in-out lg:mx-0 text-xl;
    &.my-resume {
        @apply w-2/4 lg:w-1/4 block lg:mx-auto;
    }
    &:hover {
        @apply bg-primary-500 text-white;
    }
}

//cards
.cards {
    @apply grid lg:grid-cols-2 gap-16;
}

.card {
    @apply block relative rounded-3xl overflow-hidden shadow-xl bg-white;
    height: 360px;
}

.card__image {
    @apply w-full h-auto;
}

.card__overlay {
    @apply absolute bottom-0 left-0 right-0 z-10 rounded-3xl bg-primary-05 backdrop-blur-xl transition-all ease-in-out;
    transform: translateY(100%);
}

@media (min-width: 1024px) {
    .card:hover .card__overlay {
        transform: translateY(0%);
    }
    .card:hover .card__header {
        transform: translateY(0);
        box-shadow: 1px -25px 43px -27px rgba(0, 0, 0, 0.4);
        -webkit-box-shadow: 1px -25px 43px -27px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: 1px -25px 43px -27px rgba(0, 0, 0, 0.4);
    }
}

.card__header {
    @apply p-4 rounded-t-3xl bg-primary-05 backdrop-blur-xl transition-all ease-in-out;
    transform: translateY(-100%);
    h4 {
        @apply text-2xl font-bold leading-tight;
    }
}


.card__status {
    opacity: 0.5;
}

.card__description {
    @apply px-8 pb-8;
}

.featured_works {
    h3 {
        @apply mt-20 mb-10 text-center text-lg font-bold uppercase tracking-widest;
    }
}

.work_list-content {
    @apply flex flex-col gap-2;
    h4 {
        @apply text-2xl font-bold leading-tight;
    }
    p {
        @apply text-lg;
    }
}

// About page
.about {
    h1 {
        @apply text-4xl lg:text-8xl font-bold mt-4 mt-8 leading-tight;
    }
    h2 {
        @apply text-3xl font-bold leading-tight lg:leading-relaxed;
    }
    p, li{
        @apply text-2xl font-light leading-tight my-4 lg:leading-relaxed;
    }
    ul{
        li {
            @apply ml-8;
            list-style-type:circle;
        }
    }
}

// Case Study
.case_study {
    @apply mt-4;
    section {
        @apply text-xl;
    }
    .case_study-header {
        @apply font-bold text-center px-8;
        h1 {
            @apply text-4xl mt-8;
        }
        h6 {
            @apply text-sm py-2 lg:p-2 border-b-2 mx-auto lg:w-2/5 font-normal uppercase border-dashed border-black;
        }
    }
}
.case_study-meta {
    @apply mt-10;
    ul {
        //@apply grid grid-cols-1 md:grid-cols-5 gap-2;
        @apply flex flex-wrap lg:flex-nowrap justify-between;
    }
    li {
        @apply py-2 pr-4;
    }
    li:last{
        @apply pr-0;
    }
    strong {
        @apply block pt-2;
    }
    span {
        @apply block;
    }
}
.case_study-content {
    @apply mt-10;

    h2,
    h3,
    h4 {
        @apply font-bold mt-5;
    }
    h2 {
        @apply text-2xl;
    }
    h3 {
        @apply text-xl;
    }
    h4 {
        @apply text-lg;
    }
    p {
        @apply font-normal leading-relaxed my-2;
    }
    img,
    ul {
        @apply my-5;
    }
    li {
        @apply list-disc list-outside ml-5;
    }
    img {
        @apply w-full shadow-xl;
    }
}
.case_study-footer {
    @apply pt-10 border-t border-dashed border-gray-400;
    h4{
        @apply text-xl font-bold mb-2
    }
    h5 span {
        @apply block font-light text-sm text-black normal-case;        
    }
    a {
        @apply text-primary-500 block p-4 mr-4 bg-white shadow-xl rounded-xl lg:h-40;
        //height: 150px;
    }
    
}

// Chart Tool Tip

#chart .apexcharts-tooltip {
    @apply p-2 text-center 
}

#chart .apexcharts-tooltip span {
    @apply block font-bold text-primary-500;
}
